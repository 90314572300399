import { Typography } from '@mui/material';
import React, { useEffect } from 'react';

export default function GetTotal({ pounds, euro, dollar, amount1, amount2, familyAmount, numberOfFamily }) {
  let amount1Accumulator;
  let amount2Accumulator;
  let familyAccumulator;
  let totalAccumulator;

  const application = JSON.parse(localStorage.getItem('application')) || '';
  const [total, setTotal] = React.useState(0);

  useEffect(() => {
    if (application.amount1ToPay !== 0) {
      if (application.currency1 === 'GBP') {
        amount1Accumulator = pounds * amount1;
      } else if (application.currency1 === 'USD') {
        amount1Accumulator = dollar * amount1;
      } else if (application.currency1 === 'EUR') {
        amount1Accumulator = euro * amount1;
      } else {
        amount1Accumulator = amount1;
      }
    } else {
      amount1Accumulator = 0;
    }

    if (application.amount2ToPay !== 0) {
      if (application.currency2 === 'GBP') {
        amount2Accumulator = pounds * amount2;
      } else if (application.currency2 === 'USD') {
        amount2Accumulator = dollar * amount2;
      } else if (application.currency2 === 'EUR') {
        amount2Accumulator = euro * amount2;
      } else {
        amount2Accumulator = amount2;
      }
    } else {
      amount2Accumulator = 0;
    }

    if (application.familyAmountToPay !== 0) {
      if (application.familyCurrency === 'GBP') {
        familyAccumulator = pounds * familyAmount * numberOfFamily;
      } else if (application.familyCurrency === 'USD') {
        familyAccumulator = dollar * familyAmount * numberOfFamily;
      } else if (application.familyCurrency === 'EUR') {
        familyAccumulator = euro * familyAmount * numberOfFamily;
      }else if(application.familyCurrency==="GPB") {
        familyAccumulator = pounds * familyAmount * numberOfFamily;
      }else if(application.familyCurrency==="USD") {
        familyAccumulator = dollar * familyAmount * numberOfFamily;
      }else if(application.familyCurrency==="Euro") {
        familyAccumulator = euro * familyAmount * numberOfFamily;
      }
      else {
        familyAccumulator = familyAmount * numberOfFamily;
      }
    } else {
      familyAccumulator = 0;
    }

    totalAccumulator = amount1Accumulator + amount2Accumulator + familyAccumulator;
    console.log(totalAccumulator);
    setTotal(totalAccumulator);

  }, []);
  return (
    <div>
      <Typography>
        {total < 1 ? (
          <i>calculating total</i>
        ) : (
          new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'NGN',
          }).format(total)
        )}
      </Typography>
    </div>
  );
}
