import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
import AccountManagement from './pages/AccountManagement';
import BalancePayments from './pages/BalancePayments';
import Invoices from './pages/Invoices';
//

import Login from './pages/Login';
import NotFound from './pages/Page404';
import Payments from './pages/Payments';
import PaymentUploads from './pages/PaymentUploads';
import Register from './pages/Register';
import VeritasApplication from './pages/VeritasApplication';
import CheckEmail from './pages/CheckEmail';
import ChangePassword from './pages/ChangePassword';
import ApplicationOverview from './pages/ApplicationOverview';
import UnconfirmedPayments from './pages/UnconfirmedPayments';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <VeritasApplication /> },
        { path: 'invoices', element: <Invoices /> },
        { path: 'account', element: <AccountManagement /> },
        { path: 'payments', element: <Payments /> },
        { path: 'upload-payment-proof', element: <PaymentUploads /> },
        { path: 'balance-payment-proof', element: <BalancePayments /> },
        { path: 'invoices', element: <Invoices /> },
        { path: 'application/overiew', element: <ApplicationOverview /> },
        {path:'payments/awaiting', element: <UnconfirmedPayments />}
      ],
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Login /> },
        { path: 'register', element: <Register /> },
        { path: '404', element: <NotFound /> },
        { path: 'forgot-password', element: <CheckEmail /> },
        {path: 'account/verification/:token', element: <ChangePassword />},
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
