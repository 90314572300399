import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDD2RZYUjm-kabrhs5_A_6YwOyeTt2-E9U",
  authDomain: "veritas-a1de8.firebaseapp.com",
  projectId: "veritas-a1de8",
  storageBucket: "veritas-a1de8.appspot.com",
  messagingSenderId: "611739313436",
  appId: "1:611739313436:web:e8b926a3bea51f810786d9"
};
const firebaseApp = initializeApp(firebaseConfig);

// Get a reference to the storage service, which is used to create references in your storage bucket
const storage = getStorage(firebaseApp);

export default storage
