/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Stack, Button, Container, Typography, Alert, Skeleton } from '@mui/material';
import MaterialTable from 'material-table';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import { checkvalidity } from '../components/veritas/checkValidity';

export default function Invoices() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [invoices, setInvoices] = useState([]);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const savedUser = JSON.parse(localStorage.getItem('savedUser')) || '';
  const token = JSON.parse(localStorage.getItem('token')) || '';

  useEffect(() => {
    if (!savedUser || !token) {
      navigate('/');
    } else {
      checkvalidity().then(() => {
        fetch('https://homeclass-veritas-api.vercel.app/api/fetch-invoices', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-access-token': token,
          },
          body: JSON.stringify({
            customerId: savedUser.customerId,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            console.log(data.invoices);
            console.log(data);
            if (data.error) {
              navigate('/');
            } else if (data.invalid) {
              setMessage(data.invalid);
              setOpen(true);
            } else {
              setInvoices(data.invoices);
            }

            setIsLoading(false);
          });
      });
    }
  }, []);
  return (
    <Page title="Dashboard | Veritas Invoices">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Veritas Invoices
          </Typography>
        </Stack>
        <Card>
          {open ? (
            <Alert severity="error">{message}</Alert>
          ) : (
            <Scrollbar>
              {isLoading ? (
                <Stack spacing={1}>
                  <Skeleton variant="text" />
                  <Skeleton variant="circular" width={40} height={40} />
                  <Skeleton variant="rectangular" width={210} height={118} />
                  <Skeleton variant="rectangular" width={210} height={118} />
                  <Skeleton variant="circular" width={40} height={40} />
                  <Skeleton variant="text" />
                </Stack>
              ) : (
                <MaterialTable
                  title="Veritas Invoices"
                  columns={[
                    {
                      title: 'Service Name',
                      field: 'serviceName',
                    },
                    {
                      title: 'Visa Amount',
                      field: 'amount1',
                      render: (rowData) => {
                        let option = null;
                        if (rowData.amount1ToPay === rowData.amount1) {
                          option = (
                            <Typography variant="inherit">
                              {new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: rowData.currency1,
                              }).format(rowData.amount1)}
                              Amount Remaining: {new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: rowData.currency1 || 'NGN',
                              }).format(rowData.amount1Remaining)}
                              <br />
                              {rowData.amount1Status===5?<Alert severity='success'>Cleared</Alert>:null}
                              
                            </Typography>
                          );
                        } else {
                          option = (
                            <Typography variant="inherit">
                              {new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: rowData.currency1,
                              }).format(rowData.amount1)}
                              (Deposit Amount Expected:{' '}
                              {new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: rowData.currency1,
                              }).format(rowData.amount1ToPay)}
                              )
                              Amount Remaining: {new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: rowData.currency1 || 'NGN',
                              }).format(rowData.amount1Remaining)}
                              <br />
                              {rowData.amount1Status===5?<Alert severity='success'>Cleared</Alert>:null}
                              
                            </Typography>
                          );
                        }
                        return option;
                      },
                    },
                    {
                      title: 'Other Payment',
                      field: 'amount2',
                      render: (rowData) => {
                        let option = null;

                        if (rowData.amount2 === 0 || !rowData.amount2) {
                          option = <Typography variant="inherit">No Other Payment(s) required</Typography>;
                        } else if (rowData.amount2ToPay === rowData.amount2) {
                          option = (
                            <Typography variant="inherit">
                              {new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: rowData.currency2,
                              }).format(rowData.amount2)}
                              Amount Remaining: {new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: rowData.currency2 || 'NGN',
                              }).format(rowData.amount2Remaining)}
                              <br />
                              {rowData.amount2Status===5?<Alert severity='success'>Cleared</Alert>:null}
                              
                            </Typography>
                          );
                        } else {
                          option = (
                            <Typography variant="inherit">
                              {new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: rowData.currency2,
                              }).format(rowData.amount2)}
                              (Deposit Amount Expected:{' '}
                              {new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: rowData.currency2 || 'NGN',
                              }).format(rowData.amount2ToPay)}
                              )
                              Amount Remaining: {new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: rowData.currency2 || 'NGN',
                              }).format(rowData.amount2Remaining)}
                              <br />
                              {rowData.amount2Status===5?<Alert severity='success'>Cleared</Alert>:null}
                              
                            </Typography>
                          );
                        }
                        return option;
                      },
                    },
                    {
                      title: 'Payment per family member',
                      field: 'amount2',
                      render: (rowData) => {
                        let option = null;

                        if (rowData.familyPayment === 0 || !rowData.familyPayment) {
                          option = <Typography variant="inherit">No Family Payment(s) required</Typography>;
                        } else if (rowData.familyPayment === rowData.familyAmountToPay) {
                          option = (
                            <Typography variant="inherit">
                              {new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: rowData.familyCurrency,
                              }).format(rowData.familyPayment)} X ({rowData.numberOfFamily})
                            </Typography>
                          );
                        } else {
                          option = (
                            <Typography variant="inherit">
                              {new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: rowData.familyCurrency,
                              }).format(rowData.familyPayment)}
                              (Deposit Amount Expected:{' '}
                              {new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: rowData.familyCurrency,
                              }).format(rowData.familyAmountToPay)} X ({rowData.numberOfFamily})
                              )
                              Amount Remaining: {new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: rowData.familyCurrency || 'NGN',
                              }).format(rowData.familyAmountRemaining)} X ({rowData.numberOfFamily})
                              <br />
                              {rowData.familyPaymentStatus===5?<Alert severity='success'>Cleared</Alert>:null}
                              
                            </Typography>
                          );
                        }
                        return option;
                      },
                    },
                    {
                      title:"Number of Family",
                      field:"numberOfFamily",
                      render: (rowData)=>{
                        let option = null
                        if(rowData.numberOfFamily === 0){
                          option = <Typography>No Family member taken</Typography>
                        }else{
                          option = <Typography>{rowData.numberOfFamily}</Typography>
                        }
                        return option
                      }
                    },
                    {
                      title: 'Action',
                      field: 'payment',
                      render: (rowData) => {
                        return (
                          <Button
                            variant="contained"
                            color="success"
                            onClick={() => {
                              checkvalidity()
                                .then(() => {
                                  localStorage.setItem('application', JSON.stringify(rowData));
                                  navigate('/dashboard/upload-payment-proof');
                                 
                                })
                                .catch(() => {
                                  navigate('/');
                                });
                            }}
                          >
                            Pay
                          </Button>
                        );
                      },
                    },
                  ]}
                  data={invoices}
                  options={{
                    pageSize: 2,
                    sorting: true,
                    exportButton: true,
                    actionsColumnIndex: -1,
                  }}
                />
              )}
            </Scrollbar>
          )}
        </Card>
      </Container>
    </Page>
  );
}
