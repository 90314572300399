export const checkvalidity = ()=>{
    const savedUser = JSON.parse(localStorage.getItem('savedUser')) || '';
    const token = JSON.parse(localStorage.getItem('token')) || ''

    return new Promise((resolve, reject)=>{
        if(!savedUser || !token){
            reject();
        }else{
            fetch("https://homeclass-proof-of-funds-api.vercel.app/api/check-validity",{
                headers:{
                    "Content-Type":"application/json",
                    "x-access-token": token
                }
            }).then(res=>res.json())
            .then(data=>{
                if(data.error){
                    reject()
                }else{
                    resolve()
                }
            })
        }
    })
}