/* eslint-disable */

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  Stack,
  Container,
  Typography,
  Alert,
  TableContainer,
  Box,
  Table,
  TableRow,
  TableBody,
  TableCell,
} from '@mui/material';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import { checkvalidity } from '../components/veritas/checkValidity';

export default function ApplicationOverview() {
  const overview = JSON.parse(localStorage.getItem('overview')) || '';
  const navigate = useNavigate();
  useEffect(() => {
    checkvalidity()
      .then(() => {
        if (!overview) {
          navigate('/dashboard');
        }
      })
      .catch(() => {
        navigate('/');
      });
  }, []);
  return (
    <Page title="Dashboard | Application Overview">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Application Overview({overview.serviceName})
          </Typography>
        </Stack>

        <Card>
          <Scrollbar>
            <Alert severity="info" style={{ width: '100%' }}>
              <Box sx={{ '& .MuiTextField-root': { m: 1, width: '89%' }, mt: 3 }}>
                <TableContainer>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>Service Name</TableCell>
                        <TableCell>{overview.serviceName}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Country</TableCell>
                        <TableCell>{overview.country}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Type of Package</TableCell>
                        <TableCell>{overview.packageType}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Processing Time</TableCell>
                        <TableCell>{overview.processingTime}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Visa Amount</TableCell>
                        <TableCell>
                          {new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: overview.currency1 || 'NGN',
                          }).format(overview.amount1)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Visa Amount to pay/Paid(Now)</TableCell>
                        <TableCell>{new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: overview.currency1 || 'NGN',
                          }).format(overview.amount1ToPay)}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Visa Amount to pay/Paid(Later)</TableCell>
                        <TableCell>{new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: overview.currency1 || 'NGN',
                          }).format(overview.amount1Remaining)}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Visa Amount Payment Status</TableCell>
                        <TableCell><b>{overview.amount1Status === 0?"Not Paid": overview.amount1Status === 5?"Completely Paid":"Partially Paid"}</b></TableCell>
                      </TableRow>
                       {
                        overview.numberOfPayment === 2? <><TableRow>
                          <TableCell>Other Payment Amount</TableCell>
                          <TableCell>{new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: overview.currency2 || 'NGN',
                          }).format(overview.amount2)}</TableCell>
                        </TableRow><TableRow>
                            <TableCell>Other Payment to pay/Paid(Now)</TableCell>
                            <TableCell>{new Intl.NumberFormat('en-US', {
                              style: 'currency',
                              currency: overview.currency2 || 'NGN',
                            }).format(overview.amount2ToPay)}</TableCell>
                          </TableRow><TableRow>
                            <TableCell>Other Payment to pay/Paid(Later)</TableCell>
                            <TableCell>{new Intl.NumberFormat('en-US', {
                              style: 'currency',
                              currency: overview.currency2 || 'NGN',
                            }).format(overview.amount2Remaining)}</TableCell>
                          </TableRow><TableRow>
                            <TableCell>Other Payment Status</TableCell>
                            <TableCell><b>{overview.amount2Status === 0 ? "Not Paid" : overview.amount2Status === 5 ? "Completely Paid" : "Partially Paid"}</b></TableCell>
                          </TableRow></>: null
                       }
                        {
                          overview.familyPaymentAllowed?<><TableRow>
                          <TableCell>Amount Paid for Family members</TableCell>
                          <TableCell>{new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: overview.familyCurrency || '',
                          }).format(overview.familyPayment || 0)}</TableCell>
                        </TableRow><TableRow>
                            <TableCell>Family member Payment to pay/Paid(Now)</TableCell>
                            <TableCell>{new Intl.NumberFormat('en-US', {
                              style: 'currency',
                              currency: overview.familyCurrency || 'NGN',
                            }).format(overview.familyAmountToPay)}</TableCell>
                          </TableRow><TableRow>
                            <TableCell>Family member Payment to pay/Paid(Later)</TableCell>
                            <TableCell>{new Intl.NumberFormat('en-US', {
                              style: 'currency',
                              currency: overview.familyCurrency || 'NGN',
                            }).format(overview.familyAmountRemaining)}</TableCell>
                          </TableRow><TableRow>
                            <TableCell>Family Member Status Payment Status</TableCell>
                            <TableCell><b>{overview.familyPaymentStatus === 0 ? "Not Paid" : overview.familyPaymentStatus === 5 ? "Completely Paid" : "Partially Paid"}</b></TableCell>
                          </TableRow></>: null
                        }
                        <TableRow>
                          <TableCell>Transaction Date</TableCell>
                          <TableCell>{new Date(overview.createdAt).toISOString().substring(0, 19).replace('T', ' @ ')}</TableCell>
                        </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Alert>
          </Scrollbar>
        </Card>
      </Container>
    </Page>
  );
}
