/* eslint-disable */
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Button,
  Alert,
  TextField,
  MenuItem,
} from '@mui/material';
import { checkvalidity } from './checkValidity';
import Swal from 'sweetalert2';
import GetCurrency from './GetCurrency';

export default function ApplicationDetails({ serviceName }) {
  const savedUser = JSON.parse(localStorage.getItem('savedUser')) || '';
  const token = JSON.parse(localStorage.getItem('token')) || '';
  const [packages, setPackages] = React.useState([]);
  const [isFetching, setIsFetching] = React.useState(false);
  const [numberOfFamily, setNumberOfFamily] = React.useState(0);
  const [familyIsGoing, setFamilyIsGoing] = React.useState('');
  const [isSubmitting, setSubmitting] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [slot, setSlot] = React.useState(0);

  const [openDialog, setOpenDialog] = React.useState(false);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const navigate = useNavigate();

  const submitApplication = () => {
    checkvalidity()
      .then(() => {
        if(familyIsGoing === "Yes" && numberOfFamily === 0 && packages[0].paymentRequiredForFamily){
          setMessage("Please enter the number of family members");
          setOpen(true);
        }else{
          setSubmitting(true);

        setOpen(false);
        fetch('https://homeclass-veritas-api.vercel.app/api/create-veritas-application', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-access-token': token,
          },
          body: JSON.stringify({
            customerId: savedUser.customerId,
            serviceName: packages[0].serviceName,
            packageId: packages[0]._id,
            numberOfPayment: packages[0].numberOfPayment,
            numberOfFamily: numberOfFamily,
            processingTime: packages[0].processingTime, 
            country: packages[0].country,
            packageType: packages[0].packageType,
            familyPaymentAllowed: packages[0].paymentRequiredForFamily,
            amount1: packages[0].amount1,
            depositAllowed1: packages[0].depositAllowed1,
            amount1ToPay: packages[0].depositAllowed1 ? packages[0].amountToBeDeposited1 : packages[0].amount1,
            amount1Remaining: packages[0].depositAllowed1 ? packages[0].amount1 - packages[0].amountToBeDeposited1 : 0,
            currency1: packages[0].currency1,
            amount2: packages[0].amount2,
            depositAllowed2: packages[0].depositAllowed2,
            amount2ToPay:
              packages[0].numberOfPayment === 2 && packages[0].depositAllowed2
                ? packages[0].amountToBeDeposited2
                : packages[0].numberOfPayment === 2 && !packages[0].depositAllowed2
                ? packages[0].amount2
                : 0,
            amount2Remaining:
              packages[0].numberOfPayment === 2 && packages[0].depositAllowed2
                ? packages[0].amount2 - packages[0].amountToBeDeposited2
                : packages[0].numberOfPayment === 2 && !packages[0].depositAllowed2
                ? 0
                : 0,
            currency2: packages[0].currency2,
            familyPayment: packages[0].paymentRequiredForFamily && numberOfFamily > 0 && familyIsGoing ==="Yes"? packages[0].familyPayment : 0,
            familyDepositAllowed: packages[0].familyDepositAllowed,
            familyAmountToPay:
              packages[0].paymentRequiredForFamily && packages[0].familyDepositAllowed && numberOfFamily > 0 && familyIsGoing ==="Yes"
                ? packages[0].familyAmountToBeDeposited
                : packages[0].paymentRequiredForFamily && numberOfFamily > 0 && familyIsGoing ==="Yes" && !packages[0].familyDepositAllowed
                ? packages[0].familyPayment
                : 0,
            familyAmountRemaining:
              packages[0].paymentRequiredForFamily && packages[0].familyDepositAllowed && numberOfFamily > 0 && familyIsGoing ==="Yes"
                ? packages[0].familyPayment - packages[0].familyAmountToBeDeposited
                : packages[0].paymentRequiredForFamily && numberOfFamily > 0 && familyIsGoing ==="Yes" && !packages[0].familyDepositAllowed
                ? 0
                : 0,
            familyIndividualPayment: packages[0].familyIndividualPayment,
            familyCurrency: packages[0].familyCurrency,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.error) {
              navigate('/');
            } else if (data.invalid) {
              setMessage(data.invalid);
              setOpen(true);
            } else {
              localStorage.setItem('application', JSON.stringify(data.application));
              Swal.fire({
                icon: 'success',
                title: 'Application successful',
                text: data.success,
                confirmButtonText: 'Close',
              });
              navigate('/dashboard/upload-payment-proof');
            }
            setSubmitting(false);
          });
        }
      })
      // .catch(() => {
      //   navigate('/');
      // });
  };

  useEffect(() => {
    checkvalidity()
      .then(() => {
        if (!serviceName) {
          setPackages([]);
        } else {
          setIsFetching(true);
          setOpen(false);
          fetch('https://homeclass-veritas-api.vercel.app/api/get-package-by-service-name', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'x-access-token': token,
            },
            body: JSON.stringify({
              serviceName,
            }),
          })
            .then((res) => res.json())
            .then((data) => {
              if (data.error) {
                navigate('/');
              } else if (data.packages) {
                setPackages(data.packages);
                localStorage.setItem('package', JSON.stringify(data.packages[0]));
              } else {
                setPackages([]);
                serviceName = '';
              }
            })
            .catch((error) => {
              console.log(error.message);
              setIsFetching(false);
            });

          fetch('https://homeclass-veritas-api.vercel.app/api/get-slots', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'x-access-token': token,
            },
            body: JSON.stringify({
              serviceName,
            }),
          })
            .then((res) => res.json())
            .then((data) => {
              if (data.error) {
                navigate('/');
              } else {
                setSlot(data.slot);
              }
              setIsFetching(false);
            })
            .catch((error) => {
              console.log(error);
              setIsFetching(false);
            });
        }
      })
      .catch(() => {
        navigate('/');
      });
  }, [serviceName]);

  return (
    <>
      {isFetching ? (
        <CircularProgress
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItem: 'center',
          }}
        />
      ) : packages.length === 0 ? (
        <Typography>No Package available</Typography>
      ) : (
        <Box sx={{ '& .MuiTextField-root': { m: 1, width: '89%' }, mt: 3 }}>
          {open ? (
            <Alert severity="warning" style={{ margin: '10px' }}>
              {message}
            </Alert>
          ) : null}

          <Dialog
            open={openDialog}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {'Select if you have family members you want to take along?'}
            </DialogTitle>
            <DialogContent>
              {open ? (
                <Alert severity="warning" style={{ margin: '10px' }}>
                  {message}
                </Alert>
              ) : null}
              <DialogContentText id="alert-dialog-description">
                The Veritas Visa package you selected have an option of family members to be taken along. Please select
                if you desire to take any of your family members along.{' '}
                {packages[0].familyPaymentAllowed ? 'Additional payment is required for this option' : null}
              </DialogContentText>

              <TextField
                fullWidth
                select
                label="Please select if you are taking family member along"
                value={familyIsGoing}
                onChange={(e) => setFamilyIsGoing(e.target.value)}
                style={{marginTop:"15px", marginBottom:"15px"}}
              >
                <MenuItem value="Yes">Yes, I am going with my Family</MenuItem>
                <MenuItem value="No">No, I am going only</MenuItem>
              </TextField>

              {familyIsGoing === 'Yes' ? (
                <TextField
                  fullWidth
                  label="Please enter the number of family going with you"
                  type="number"
                  value={numberOfFamily}
                  onChange={(e) => setNumberOfFamily(e.target.value)}
                />
              ) : null}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Close</Button>
              <Button
                variant="contained"
                style={{ margin: '25px', display: 'flex', width: '85%', justifyItems: 'center', alignItems: 'center' }}
                color="success"
                disabled={isSubmitting}
                onClick={submitApplication}
              >
                {isSubmitting ? <CircularProgress /> : 'Apply for Veritas Visa'}
              </Button>
            </DialogActions>
          </Dialog>
          <TableContainer>
            <Typography variant="h4" align="center" style={{ marginBottom: '20px ' }}>
              Visa Application Information
            </Typography>
            <hr />
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Visa Package</TableCell>
                  <TableCell>{serviceName}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Country</TableCell>
                  <TableCell>{packages[0].country}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Visa Classification</TableCell>
                  <TableCell>{packages[0].packageType}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Processing Time</TableCell>
                  <TableCell>{packages[0].processingTime}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Number of Slots</TableCell>
                  <TableCell>{packages[0].slot}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Number of slot remaining</TableCell>
                  <TableCell>{packages[0].slot - slot}</TableCell>
                </TableRow>
              </TableBody>
            </Table>

            <Typography variant="h4" align="center" style={{ marginBottom: '20px', marginTop: '20px' }}>
              Visa Billing Information
            </Typography>
            <hr />
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Number of Payment</TableCell>
                  <TableCell>{packages[0].numberOfPayment}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Visa Amount</TableCell>
                  <TableCell>
                    {new Intl.NumberFormat('en-US', { style: 'currency', currency: packages[0].currency1 }).format(
                      Number(packages[0].amount1)
                    )}
                    {packages[0].currency1 !== 'NGN' ? <GetCurrency currency={packages[0].currency1} /> : null}
                  </TableCell>
                </TableRow>
                {packages[0].depositAllowed1 ? (
                  <TableRow>
                    <TableCell>Amount to be deposited</TableCell>
                    <TableCell>
                      {new Intl.NumberFormat('en-US', { style: 'currency', currency: packages[0].currency1 }).format(
                        Number(packages[0].amountToBeDeposited1)
                      )}
                      {packages[0].currency1 !== 'NGN' ? <GetCurrency currency={packages[0].currency1} /> : null}
                    </TableCell>
                  </TableRow>
                ) : null}
                {packages[0].numberOfPayment === 2 ? (
                  <TableRow>
                    <TableCell>Other Payment</TableCell>
                    <TableCell>
                      {new Intl.NumberFormat('en-US', { style: 'currency', currency: packages[0].currency2 }).format(
                        Number(packages[0].amount2)
                      )}
                      {packages[0].currency2 !== 'NGN' ? <GetCurrency currency={packages[0].currency2} /> : null}
                    </TableCell>
                  </TableRow>
                ) : null}
                {packages[0].numberOfPayment === 2 && packages[0].depositAllowed2 ? (
                  <TableRow>
                    <TableCell>Other Payment(Deposit Amount)</TableCell>
                    <TableCell>
                      {new Intl.NumberFormat('en-US', { style: 'currency', currency: packages[0].currency2 }).format(
                        Number(packages[0].amountToBeDeposited2)
                      )}
                      {packages[0].currency2 !== 'NGN' ? <GetCurrency currency={packages[0].currency2} /> : null}
                    </TableCell>
                  </TableRow>
                ) : null}
                {packages[0].familyAllowed === 'Yes' ? (
                  <TableRow>
                    {packages[0].paymentRequiredForFamily ? (
                      <>
                        <TableCell>Payment Required per Family member(s)</TableCell>
                        <TableCell>
                          {new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: packages[0].familyCurrency,
                          }).format(Number(packages[0].familyPayment))}
                          {packages[0].familyCurrency !== 'NGN' ? (
                            <GetCurrency currency={packages[0].familyCurrency} />
                          ) : null}
                        </TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell>Payment Required for Family</TableCell>
                        <TableCell>Free(No Payment required) </TableCell>
                      </>
                    )}
                  </TableRow>
                ) : null}
                {packages[0].familyDepositAllowed &&
                packages[0].familyAllowed === 'Yes' &&
                packages[0].paymentRequiredForFamily ? (
                  <TableRow>
                    <TableCell>Amount To Deposit for Family {}</TableCell>
                    <TableCell>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: packages[0].familyCurrency,
                      }).format(Number(packages[0].familyAmountToBeDeposited))}
                      {packages[0].familyCurrency !== 'NGN' ? (
                        <GetCurrency currency={packages[0].familyCurrency} />
                      ) : null}
                    </TableCell>
                  </TableRow>
                ) : null}
              </TableBody>
              {
                //packages[0].familyindividualpayment should be used for payment
              }
            </Table>
          </TableContainer>
          <Typography variant="subtitle2" style={{ color: 'red', marginTop: '20px' }} align="center">
            Only payments validates slot
          </Typography>
          <br />

          {open ? (
            <Alert severity="warning" style={{ margin: '10px' }}>
              {message}
            </Alert>
          ) : null}
          <Button
            variant="contained"
            style={{ margin: '25px', display: 'flex', width: '85%', justifyItems: 'center', alignItems: 'center' }}
            color="success"
            disabled={isSubmitting}
            onClick={() => {
              if (packages[0].familyAllowed === 'Yes') {
                handleClickOpen();
              } else {
                submitApplication();
              }
            }}
          >
            {isSubmitting ? <CircularProgress /> : 'Apply for Veritas Visa'}
          </Button>
        </Box>
      )}
    </>
  );
}
