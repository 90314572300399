// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'veritas applications',
    path: '/dashboard',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'Account Management',
    path: '/dashboard/account',
    icon: getIcon('eva:people-fill'),
  },
  {
    title: 'unpaid invoices',
    path: '/dashboard/invoices',
    icon: getIcon('eva:credit-card-outline'),
  },
  {
    title: 'invoices/due payments',
    path: '/dashboard/payments/awaiting',
    icon: getIcon('eva:credit-card-outline'),
  },
  
  {
    title: 'payments',
    path: '/dashboard/payments',
    icon: getIcon('eva:credit-card-fill'),
  },
  // {
  //   title: 'support center',
  //   path: '/dashboard/support-center',
  //   icon: getIcon('eva:question-mark-fill'),
  // },
  
];

export default navConfig;
