import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  Dialog,
  AppBar,
  Toolbar,
  Slide,
  IconButton,
  Skeleton,
} from '@mui/material';
import MaterialTable from 'material-table';
import CloseIcon from '@mui/icons-material/Close';

// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import { checkvalidity } from '../components/veritas/checkValidity';
import NewApplication from '../components/veritas/NewApplication';

// eslint-disable-next-line
let Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function VeritasApplication() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const savedUser = JSON.parse(localStorage.getItem('savedUser')) || '';
  const token = JSON.parse(localStorage.getItem('token')) || ''
  const [applications, setApplications] = useState([])

  useEffect(() => {
    checkvalidity()
      .then(() => {
        setIsLoading(true);
        fetch("https://homeclass-veritas-api.vercel.app/api/fetch-veritas-application",{
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-access-token': token,
          },
          body:JSON.stringify({
            customerId: savedUser.customerId
          })
        }).then(res=>res.json())
        .then(data=>{
          if(data.error){
            navigate("/")
          }else{
            setApplications(data.application)
          }
          setIsLoading(false)
        })
      })
      .catch(() => {
        navigate('/');
      });
  }, []);

  const [open, setOpen] = useState(false);

  const handleClickOpen = async () => {
    await checkvalidity()
      .then(() => {
        setOpen(true);
      })
      .catch(() => {
        navigate('/');
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Page title="Dashboard | Veritas Application">
      <Container>
        <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Create New Veritas Visa Application
              </Typography>
              <Button autoFocus color="inherit" onClick={handleClose}>
                Exit
              </Button>
            </Toolbar>
          </AppBar>
          <NewApplication />
        </Dialog>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Manage Veritas Visa
          </Typography>
          <Button variant="contained" onClick={handleClickOpen} startIcon={<Iconify icon="eva:plus-fill" />}>
            Apply for a new Visa
          </Button>
        </Stack>

        <Card>
          <Scrollbar>
            {isLoading ? (
              <Stack spacing={1}>
                <Skeleton variant="text" />
                <Skeleton variant="circular" width={40} height={40} />
                <Skeleton variant="rectangular" width={210} height={118} />
                <Skeleton variant="rectangular" width={210} height={118} />
                <Skeleton variant="circular" width={40} height={40} />
                <Skeleton variant="text" />
              </Stack>
            ) : (
              <MaterialTable
                title="Veritas Application"
                columns={[
                  {
                    title: 'Service Name',
                    field: 'serviceName',
                  },

                  {
                    title: 'Processing Time',
                    field: 'processingTime',
                  },

                  {
                    title: 'Visa Package',
                    field: 'packageType',
                  },
                  {
                    title: 'Country',
                    field: 'country',
                  },

                  {
                    title: 'Action',
                    field: 'action',
                    render: (rowData) => {
                      return (
                        <Button variant="contained" size='small' onClick={()=>{
                          checkvalidity().then(()=>{
                            localStorage.setItem('overview', JSON.stringify(rowData))
                            navigate("/dashboard/application/overiew")
                          }).catch(()=>{
                            navigate("/")
                          })
                        }}>
                          View Details
                        </Button>
                      );
                    },
                  },
                ]}
                data={applications}
                options={{
                  pageSize: 2,
                  sorting: true,
                  exportButton: true,
                  actionsColumnIndex: -1,
                }}
              />
            )}
          </Scrollbar>
        </Card>
      </Container>
    </Page>
  );
}
