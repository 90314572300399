/* eslint-disable */

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Box, Autocomplete, MenuItem, Alert } from '@mui/material';
import { countries } from './countries';
import { checkvalidity } from './checkValidity';
import ApplicationDetails from './ApplicationDetails';

export default function NewApplication() {
  const [alert, setAlert] = useState('error');
  const [message, setMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [country, setCountry] = useState('');
  const [serviceName, setServiceName] = useState('');
  const [packages, setPackages] = useState([]);
  const token = JSON.parse(localStorage.getItem('token')) || '';

  const navigate = useNavigate();

  useEffect(() => {
    checkvalidity()
      .then(() => {
        setIsLoading(true);
        fetch('https://veritas-visa.herokuapp.com/api/get-package-by-country', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-access-token': token,
          },
          body: JSON.stringify({
            country,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.error) {
              navigate('/');
            } else if (data.invalid) {
              setMessage(data.invalid);
              setAlert('warning')
              setOpen(true)
              setServiceName('')
            } else if(data.packages) {
              setPackages(data.packages);
              
            }else{
              setServiceName('')
              setPackages([])
            }
            setIsLoading(false);
          });
      })
      .catch(() => {
        navigate('/');
      });
  }, [country]);

  return (
    <>
      <Box
        sx={{
          '& .MuiTextField-root': { m: 1, width: '89%' },
          margin: '25px',
        }}
      >
        {open && !country ? (
          <Alert severity={alert} style={{ margin: '12px' }}>
            {message}
          </Alert>
        ) : null}

        <Autocomplete
          filterSelectedOptions
          id="country"
          name="country"
          options={countries}
          clearText="Nigeria"
          // Add the following props
          isOptionEqualToValue={(option, value) => option.label === value}
          value={country}
          renderInput={(params) => (
            <TextField {...params} helperText={'search for country'} label="Choose a Country of your choice" />
          )}
          onChange={(e, newValue) => {
            if(!newValue){
              setCountry('');
            }else{
              setCountry(newValue.label);
            }
          }}
        />

        {country ? (
          <TextField
            label="Select Application Visa"
            value={isLoading ? 'Loading...' : !serviceName?'':serviceName}
            onChange={(e) => setServiceName(e.target.value)}
            select
            helperText="Please select application visa"
          >
            {packages.length === 0 && !isLoading ? (
              <MenuItem>No Application Visa available for this country</MenuItem>
            ) : isLoading ? (
              <MenuItem>
                <i>Loading...</i>
              </MenuItem>
            ) : (
              packages.map((item) => {
                return (
                  <MenuItem key={item.serviceName} value={item.serviceName}>
                    {item.serviceName}
                  </MenuItem>
                );
              })
            )}
          </TextField>
        ) : null}
      </Box>

      {!serviceName || packages.length===0 ? null : <ApplicationDetails serviceName={serviceName} />}
    </>
  );
}
