/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  Alert,
  TextField,
  Box,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  CircularProgress,
} from '@mui/material';
import InputTwoToneIcon from '@mui/icons-material/InputTwoTone';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import storage from '../components/veritas/firestore';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import { checkvalidity } from '../components/veritas/checkValidity';
import Swal from 'sweetalert2';
import GetTotal from '../components/veritas/GetTotal';

export default function PaymentUploads() {
  const application = JSON.parse(localStorage.getItem('application')) || '';
  const savedUser = JSON.parse(localStorage.getItem('savedUser')) || '';
  const token = JSON.parse(localStorage.getItem('token')) || '';
  const [upload, setUpload] = useState(false);
  const [familyEntry, setFamilyEntry] = useState(false);

  // setting up amount to pay

  const [amount1, setAmount1] = useState(0);
  const [amount2, setAmount2] = useState(0);
  const [familyAmount, setFamilyAmount] = useState(0);
  const [dollar, setDollar] = useState(0);
  const [pounds, setPounds] = useState(0);
  const [euro, setEuro] = useState(0);

  const [file, setFile] = useState();
  const [message, setMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [loading, setIsLoading] = useState(false);

  //setting up upload paramenters

  const navigate = useNavigate();

  const getConversionRate = () => {
    fetch('https://homeclass-veritas-api.vercel.app/api/get-conversion-rate', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': token,
      },
      body: JSON.stringify({
        currency: 'USD',
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          navigate('/');
        } else {
          setDollar(Number(data.rate.rate));
        }
      });
  };
  const getConversionRate1 = () => {
    fetch('https://homeclass-veritas-api.vercel.app/api/get-conversion-rate', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': token,
      },
      body: JSON.stringify({
        currency: 'GBP',
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          navigate('/');
        } else {
          setPounds(Number(data.rate.rate));
        }
      });
  };

  const getConversionRate2 = () => {
    fetch('https://homeclass-veritas-api.vercel.app/api/get-conversion-rate', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': token,
      },
      body: JSON.stringify({
        currency: 'EUR',
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          navigate('/');
        } else {
          setEuro(Number(data.rate.rate));
        }
      });
  };

  const uploadPaymentProof = () => {
    if (!file) {
      setMessage('Please upload your payment proof');
      setOpen(true);
    } else {
      setOpen(false);
      checkvalidity()
        .then(() => {
          // upload file to firebase store

          const fileName = new Date().getTime() + file.name;
          const storageRef = ref(storage, fileName);
          const uploadTask = uploadBytesResumable(storageRef, file);
          setIsLoading(true);

          uploadTask.on(
            'state_changed',
            (snapshot) => {
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log(`Upload is ${progress}% done`);
              switch (snapshot.state) {
                case 'paused':
                  console.log('Upload is paused');
                  break;
                case 'running':
                  console.log('Upload is running');
                  break;
                default:
                  console.log('error uploading file');
              }
            },
            (error) => {
              // Handle unsuccessful uploads
              console.log(error);
              setMessage(error.message);
              setOpen(true);
              setIsLoading(false);
            },
            () => {
              // Handle successful uploads on complete
              // For instance, get the download URL: https://firebasestorage.googleapis.com/...
              getDownloadURL(uploadTask.snapshot.ref).then(async (proofUrl) => {
                // save and update db;
                fetch('https://homeclass-veritas-api.vercel.app/api/upload-proof', {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                  },
                  body: JSON.stringify({
                    applicationId: application._id,
                    proofUrl,
                    conversionRate: {
                      USD: dollar,
                      GBP: pounds,
                      Euro: euro,
                    },
                    customerId: savedUser.customerId,
                    amount1Remaining: application.amount1Remaining,
                    amount2ToPay: application.amount2ToPay,
                    amount2Remaining: application.amount2Remaining,
                    familyAmountToPay: application.familyAmountToPay,
                    familyAmountRemaining: application.familyAmountRemaining,
                  }),
                })
                  .then((res) => res.json())
                  .then((data) => {
                    if (data.invalid) {
                      setMessage(data.invalid);
                      setOpen(true);
                    } else {
                      Swal.fire({
                        icon: 'success',
                        title: 'Upload Successful',
                        text: data.success,
                        confirmButtonText: 'Close',
                      });
                      localStorage.removeItem('application');
                      localStorage.removeItem('package');
                      navigate('/dashboard/payments');
                    }

                    setIsLoading(false);
                  });
              });
            }
          );
        })
        .catch(() => {
          navigate('/');
        });
    }
  };
  useEffect(() => {
    checkvalidity()
      .then(async () => {
        getConversionRate();
        getConversionRate1();
        getConversionRate2();
        if (!application) {
          navigate('/dashboard');
        } else {
          setAmount1(application.amount1ToPay);
          setAmount2(application.amount2ToPay);
          setFamilyAmount(application.familyAmountToPay);
        }
      })
      .catch(() => {
        navigate('/');
      });
  }, []);
  return (
    <Page title="Dashboard | Veritas Payments">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Upload Payment Proof
          </Typography>
        </Stack>

        <Card>
          <Scrollbar>
            {familyEntry ? (
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="number"
                  value={numberOfFamily}
                  onChange={(e) => setNumberOfFamily(e.target.value)}
                  label="Number of family members"
                  helperText="Enter the number of family members"
                />
                <IconButton
                  onClick={() => {
                    if (!numberOfFamily || numberOfFamily === 0) {
                      window.alert('Please enter the number of family members');
                    } else {
                      setFamilyEntry(false);
                    }
                  }}
                >
                  <InputTwoToneIcon />
                </IconButton>
              </div>
            ) : upload ? (
              <>
                <Alert severity="info" style={{ width: '100%' }}>
                  <p>
                    Please pay the amount displayed below into the account details below, and upload the payment proof
                  </p>
                  <Box>
                    <TableContainer>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell>Total Amount expected</TableCell>
                            <TableCell>
                              <GetTotal
                                pounds={pounds}
                                euro={euro}
                                dollar={dollar}
                                amount1={amount1}
                                amount2={amount2}
                                familyAmount={familyAmount}
                                numberOfFamily={application.numberOfFamily}
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Account Name</TableCell>
                            <TableCell>UflyRight Enterprises</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Account Number</TableCell>
                            <TableCell>1016335342</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Bank Name</TableCell>
                            <TableCell>Zenith Bank</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>

                    <TextField
                      onChange={(e) => setFile(e.target.files[0])}
                      style={{ marginTop: '10px', marginBottom: '10px' }}
                      type="file"
                      fullWidth
                      helperText="Please pay the amount displayed above to the account details displayed"
                    />
                    {open ? <Alert severity="warning">{message}</Alert> : null}
                  </Box>
                  <Button
                    variant="contained"
                    onClick={uploadPaymentProof}
                    disabled={loading}
                    style={{ width: '85%', margin: '15px' }}
                    color="success"
                  >
                    {loading ? <CircularProgress /> : 'Upload Payment Proof'}
                  </Button>
                </Alert>
              </>
            ) : (
              <Alert severity="info" style={{ width: '100%' }}>
                <p>The following are the analysis of your payment</p>
                <Box sx={{ '& .MuiTextField-root': { m: 1, width: '89%' }, mt: 3 }}>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell>Service</TableCell>
                          <TableCell>{application.serviceName}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Visa Amount</TableCell>
                          <TableCell>
                            {new Intl.NumberFormat('en-US', {
                              style: 'currency',
                              currency: application.currency1 || 'NGN',
                            }).format(application.amount1)}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Amount expected to pay(the least amount expected)</TableCell>
                          <TableCell>
                            {' '}
                            {new Intl.NumberFormat('en-US', {
                              style: 'currency',
                              currency: application.currency1 || 'NGN',
                            }).format(application.amount1ToPay)}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Remaining Visa Balance</TableCell>
                          <TableCell>
                            {' '}
                            {new Intl.NumberFormat('en-US', {
                              style: 'currency',
                              currency: application.currency1 || 'NGN',
                            }).format(application.amount1Remaining)}
                          </TableCell>
                        </TableRow>
                        {application.numberOfPayment === 2 ? (
                          <>
                            <TableRow>
                              <TableCell>Other Payment Fee</TableCell>
                              <TableCell>
                                {' '}
                                {new Intl.NumberFormat('en-US', {
                                  style: 'currency',
                                  currency: application.currency2 || 'NGN',
                                }).format(application.amount2)}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Other Payment(the least amount expected)</TableCell>
                              <TableCell>
                                {' '}
                                {new Intl.NumberFormat('en-US', {
                                  style: 'currency',
                                  currency: application.currency2 || 'NGN',
                                }).format(application.amount2ToPay)}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Other Payment Balance</TableCell>
                              <TableCell>
                                {' '}
                                {new Intl.NumberFormat('en-US', {
                                  style: 'currency',
                                  currency: application.currency2 || 'NGN',
                                }).format(application.amount2Remaining)}
                              </TableCell>
                            </TableRow>
                          </>
                        ) : null}
                        {application.familyPaymentAllowed && application.numberOfFamily > 0 ? (
                          <>
                            <TableRow>
                              <TableCell>Payment per family members</TableCell>
                              <TableCell>
                                {' '}
                                {new Intl.NumberFormat('en-US', {
                                  style: 'currency',
                                  currency: application.familyCurrency || 'NGN',
                                }).format(application.familyPayment)}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Numbers of family members</TableCell>
                              <TableCell> {application.numberOfFamily}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Payment per family members expected(the least amount to pay)</TableCell>
                              <TableCell>
                                {' '}
                                {new Intl.NumberFormat('en-US', {
                                  style: 'currency',
                                  currency: application.familyCurrency || 'NGN',
                                }).format(application.familyAmountToPay * application.numberOfFamily || 0)}
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell>Balance for Family Payment</TableCell>
                              <TableCell>
                                {' '}
                                {new Intl.NumberFormat('en-US', {
                                  style: 'currency',
                                  currency: application.familyCurrency,
                                }).format(application.familyAmountRemaining)}
                              </TableCell>
                            </TableRow>
                          </>
                        ) : null}
                        <TableRow>
                          <TableCell>Current currency rates</TableCell>
                          <TableCell>{`USD: ${dollar || 'Not available'}, GBP: ${pounds || 'Not availbale'}, EUR: ${euro || 'Not available'} `}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Typography variant="subtitle2" style={{ color: 'red', marginTop: '20px' }} align="center">
                    click on continue button to view total amount to pay in Naira equivalence, view account details and
                    upload payment proof
                  </Typography>
                  <Button
                    variant="contained"
                    style={{
                      margin: '25px',
                      display: 'flex',
                      width: '85%',
                      justifyItems: 'center',
                      alignItems: 'center',
                    }}
                    color="success"
                    onClick={() => {
                     
                      setUpload(true);
                    }}
                  >
                    Continue
                  </Button>
                </Box>
              </Alert>
            )}
          </Scrollbar>
        </Card>
      </Container>
    </Page>
  );
}
