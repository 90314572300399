import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
// material
import {
  Alert,
  Backdrop,
  CircularProgress,
  IconButton,
  InputAdornment,
  Skeleton,
  Stack,
  TextField,
} from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
// component
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

export default function ChangePasswordForm() {
  const navigate = useNavigate();

  const { token } = useParams();

  const [showPassword, setShowPassword] = useState(false);
  const [message, setMessage] = useState('');
  const [alert, setAlert] = useState('error');
  const [isVerifying, setIsVerifying] = useState(true);
  const [isError, setIsError] = useState(false);

  const ChangePasswordSchema = Yup.object().shape({
    password: Yup.string().required('Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      password: '',
    },
    validationSchema: ChangePasswordSchema,
    onSubmit: () => {
      setIsVerifying(true)

      fetch('https://homeclass-proof-of-funds-api.vercel.app/api/password/change-customer-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token,
          newPassword: formik.values.password,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.error) {
            setMessage(data.error);
            setAlert('error');
            setIsError(true);
          } else {
            window.alert('Password has been changed successfully');
            navigate('/', { replace: true });
          }
          setIsVerifying(false);
        })
        .catch((error) => {
          console.log(error.message);
          console.warn(error);
          console.error(error);
          setMessage('Unable to connect to sever... Please check your connection');
          setAlert('error');
          setIsError(true)
          setIsVerifying(false)
        });
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  useEffect(() => {
    if (token) {
      setIsVerifying(true);
      setIsError(true);
      setAlert('info');
      setMessage('verifying');
      fetch('https://homeclass-proof-of-funds-api.vercel.app/api/password/verify-token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.error) {
            setIsVerifying(false);
            setIsError(true);
            setAlert('error');
            setMessage(data.error);
          } else {
            setIsVerifying(false);
            setIsError(false);
          }
          
        });
    } else {
      navigate('/forgot-password');
    }
  }, [token]);

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        {isVerifying ? (
          <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isVerifying}>
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : null}

        {isError ? (
          <Alert severity={alert} style={{ margin: '10px', width: '80%', height: '100px' }}>
            {message}
          </Alert>
        ) : (
          <>
            <Stack spacing={3}>
              <TextField
                fullWidth
                autoComplete="current-password"
                type={showPassword ? 'text' : 'password'}
                label="Password"
                {...getFieldProps('password')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowPassword} edge="end">
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
              />
            </Stack>

            <LoadingButton fullWidth size="large" type="submit" variant="contained">
              Change Password
            </LoadingButton>
          </>
        )}
      </Form>
    </FormikProvider>
  );
}
