import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import { checkvalidity } from './checkValidity';

export default function GetCurrency({ currency }) {

    const [rate, setRate] = React.useState('')
    const token = JSON.parse(localStorage.getItem('token')) || '';
    const navigate = useNavigate()
    const getConversionRate = ()=>{
      fetch("https://homeclass-veritas-api.vercel.app/api/get-conversion-rate",{
          method:"POST",
          headers: {
              'Content-Type': 'application/json',
              'x-access-token': token,
          },
          body: JSON.stringify({
              currency
          })
      }).then(res=>res.json())
      .then(data=>{
          if(data.error){
              navigate("/")
          }else{
              setRate(Number(data.rate.rate))
              
          }
      })
    }
    useEffect(()=>{
        checkvalidity().then(()=>{
            getConversionRate()
        }).catch(()=>{
            navigate("/")
        })
    },[currency])
  return (
    <Typography variant="inherit">
      <i style={{ color: 'red' }}>
        current conversion rate({currency}): {new Intl.NumberFormat('en-US', { style: 'currency', currency: "NGN" }).format(rate) || 'fetching conversion rate'}
      </i>
    </Typography>
  );
}
